import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const SignUpAsASoleTraderViaAccountantInvite = () => {
  return (
    <Layout>
      <Seo
        title="How to Sign Up as a Sole Trader via Accountant Invite"
        description={`Learn how to Sign Up as a Sole Trade via Accountant Invite`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`sign-up-as-a-sole-trader-via-accountant-invite`} />
    </Layout>
  )
}

export default SignUpAsASoleTraderViaAccountantInvite
